import { Base64 } from 'js-base64';
import { ActionCreatorWithPayload, AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { baseUrl } from '@shared/constants';
import { AppDescType, NewAppType, NewInitiatorType, UserInfoType } from '@shared/types';

// обработчик ответа с сервера
const handleReturn = (res: any) => (res.ok ? res.json() : Promise.reject(res.status));

export const handleHeaders = () => {
  const pass = localStorage.getItem('userInfo');
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: pass || '',
  };
};

// авторизация
export const handleAuth = (v: string) => {
  return fetch(`${baseUrl}auth`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: v,
    },
    body: JSON.stringify({}),
  }).then((res) => handleReturn(res));
};

/**
 * простой запрос
 * @param url_text адрес по которому обращаться
 * @param reqBody объект в теле запроса
 * @param method метод, по умолчанию POST
 * @returns
 */
export const fetchSimple = (url_text: string, reqBody: any = {}, method = 'POST') => {
  let bodyObject = {};
  Object.keys(reqBody).length !== 0 && (bodyObject = { ...bodyObject, ...reqBody });
  return fetch(`${baseUrl}${url_text}`, {
    method,
    headers: handleHeaders(),
    body: JSON.stringify(bodyObject),
  }).then((res) => handleReturn(res));
};

// отправка GET запроса на сервер
// Initiators,Services,
export const fetchData = (
  url: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  successAction: any,
  errorAction: ActionCreatorWithPayload<string, 'apps/setErrorMessage'>
): Promise<any> => {
  return fetch(`${baseUrl}${url}`, {
    method: 'GET',
    headers: handleHeaders(),
  })
    .then((res) => handleReturn(res))
    .then((res) => dispatch(successAction(res)))
    .catch((err) => dispatch(errorAction(err.message)));
};

// отправка POST запроса на сервер
// Users,Applications
export const fetchPOSTData = (
  url: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  successAction: any,
  errorAction: any
): Promise<any> => {
  return fetch(`${baseUrl}${url}`, {
    method: 'POST',
    headers: handleHeaders(),
    body: JSON.stringify({}),
  })
    .then((res) => handleReturn(res))
    .then((res) => dispatch(successAction(res)))
    .catch((err) => dispatch(errorAction(err.message)));
};

// отправка POST запроса на сервер
// Organizations ,
export const fetchDataPostSimple = (url: string, body?: any[]): Promise<any> => {
  return fetch(`${baseUrl}${url}`, {
    method: 'POST',
    headers: handleHeaders(),
    body: JSON.stringify({guid: body}),
  }).then((res) => handleReturn(res));
};

// простой запрос
export const fetchAPISimple = (url_text: string, reqBody?: any, method = 'POST') => {
  let bodyObject = {};
  Object.keys(reqBody).length !== 0 && (bodyObject = { ...bodyObject, ...reqBody });
  return fetch(`${baseUrl}${url_text}`, {
    method,
    headers: handleHeaders(),
    body: JSON.stringify(bodyObject),
  }).then((res) => handleReturn(res));
};

// отправка POST запроса на сервер
// категории, организации
export const fetchDataPost = (url: string, UID: string) => {
  return fetch(`${baseUrl}${url}`, {
    method: 'POST',
    headers: handleHeaders(),
    body: JSON.stringify({
      UID: UID,
    }),
  }).then((res) => handleReturn(res));
};

// создание нового пользователя
// 1. загрузить список организаций для создания нового пользователя
export const handleDownloadCounteragents = () => {
  return fetch(`${baseUrl}Counteragents`, {
    method: 'POST',
    headers: handleHeaders(),
  }).then((res) => handleReturn(res));
};
// 2. на основании выбранной организации загрузить список подразделений
export const handleDownloadDivisions = (Counteragent: string, IsInitiator: boolean) => {
  const urlLink = IsInitiator
    ? `${baseUrl}Divisions/${Counteragent}`
    : `${baseUrl}Departments/${Counteragent}`;
  return fetch(urlLink, {
    method: 'POST',
    headers: handleHeaders(),
    body: JSON.stringify({}),
  }).then((res) => handleReturn(res));
};
// 3. создание нового пользователя
export const handleCreateNewUser = (values: NewInitiatorType, IsInitiator: boolean) => {
  const result = {
    ...values,
    IsInitiator,
    Organization: values.Organization.UID,
    Department: values.Department.UID,
  };
  return fetch(`${baseUrl}NewUser`, {
    method: 'POST',
    headers: handleHeaders(),
    body: JSON.stringify(result),
  }).then((res) => handleReturn(res));
};

// Загрузка одного пользователя с сервера
export const handleDownloadUser = (userUID: string, IsInitiator: boolean) => {
  return fetch(`${baseUrl}ProfileData`, {
    method: 'POST',
    headers: handleHeaders(),
    body: JSON.stringify({
      UID: userUID,
      IsInitiator: IsInitiator,
    }),
  }).then((res) => handleReturn(res));
};
// Редактирование информации о пользователе (IsInitiator true для инициаторов / false для остальных)
export const handleEditUserInfo = (values: UserInfoType, IsInitiator: boolean) => {
  const result = {
    IsInitiator: IsInitiator,
    UID: values.UID, // UID редактируемого (обязательный)
    FullName: values.FullName,
    Name: values.Name,
    // Organization: values.Organization.UID,
    Department: values.Department.UID,
    Position: values.Position,
    Address: values.Address,
    // Email: values.Email,
    Phone: values.Phone,
  };
  IsInitiator
    ? Object.assign(result, {
        Priority: values.Priority,
        Telegramm: values.Telegramm,
        NotificationResolution: values.NotificationResolution,
        Comment: values.Comment,
        Service: values.Service.UID,
        Category: values.Category.UID,
      })
    : Object.assign(result, { TakeApplications: values.TakeApplications });
  return fetch(`${baseUrl}EditProfileData`, {
    method: 'POST',
    headers: handleHeaders(),
    body: JSON.stringify(result),
  }).then((res) => handleReturn(res));
};

// Редактирование аватара пользователе (IsInitiator true для инициаторов / false для остальных)
export const handleEditUserAvatar = (Avatar: string, UID: string, IsInitiator: boolean) => {
  return fetch(`${baseUrl}EditProfileData`, {
    method: 'POST',
    headers: handleHeaders(),
    body: JSON.stringify({ Avatar: Avatar, UID: UID, IsInitiator: IsInitiator }),
  }).then((res) => handleReturn(res));
};

// Удаление инициатора
export const handleDeleteUser = (UID: string) => {
  return fetch(`${baseUrl}DeieteInitiator/${UID}`, {
    method: 'POST',
    headers: handleHeaders(),
  }).then((res) => handleReturn(res));
};

// заявки
// загрузка определенной по UID
export const handleDownloadApplicationDesc = (ApplicationUID: string) => {
  return fetch(`${baseUrl}uid`, {
    method: 'POST',
    headers: handleHeaders(),
    body: JSON.stringify({ UID: ApplicationUID }),
  }).then((res) => handleReturn(res));
};
// создать новую
export const handleCreateNewApplication = (item: NewAppType, coordUID: string) => {
  const result = {
    Subject: item.Subject,
    Priority: item.Priority,
    Note: item.Note,
    Status: item.Status,
    Address: item.Address,
    Initiator: item.Initiator.UID,
    Service: item.Service.UID,
    Coordinator: coordUID,
  };
  !!coordUID && Object.assign(result, { Coordinator: coordUID });
  item.Category?.UID && Object.assign(result, { Category: item.Category.UID });
  item.Executor?.UID && Object.assign(result, { Executor: item.Executor.UID });
  return fetch(`${baseUrl}new`, {
    method: 'POST',
    headers: handleHeaders(),
    body: JSON.stringify(result),
  }).then((res) => handleReturn(res));
};
// отредактировать
export const handleEditApplication = (item: AppDescType) => {
  const result = {
    // Subject: item.Subject, // Тема заявки (обязательный)
    // Note: item.Note, // описание задачи в формате HTML
    Status: item.Status, // статус заявки
    Service: item.Service.UID, // UID сервиса
    Category: item.Category.UID, // категория заявки
    Priority: item.Priority, // приоритет заявки
    Executor: item.Executor.UID, // исполнитель заявки
    Coordinator: item.Coordinator.UID, // координатор заявки
  };
  return fetch(`${baseUrl}edit/${item.UID}`, {
    method: 'PATCH',
    headers: handleHeaders(),
    body: JSON.stringify(result),
  }).then((res) => {
    if (res.ok) {
      return res;
    }
    return Promise.reject(res.status);
  });
};
// добавить комментарий
export const handleSendNewComment = (item: any) => {
  return fetch(`${baseUrl}addcomment/${item.UID}`, {
    method: 'POST',
    headers: handleHeaders(),
    body: JSON.stringify({
      Note: item.Note, // Содержание комментария
    }),
  }).then((res) => {
    if (res.ok) {
      return res;
    }
    return Promise.reject(res.status);
  });
};

// база знаний
export const handleDownloadKnowBase = (v: string) => {
  const body = JSON.stringify(v.length ? { UID: v } : {});
  return fetch(`${baseUrl}KnowledgeBase${v.length ? '?UID=' + v : ''}`, {
    method: 'POST',
    headers: handleHeaders(),
    body,
  }).then((res) => handleReturn(res));
};

// отправить токен для уведомлений
export const handleSendNotificationToken = (item: string) => {
  return fetch(`${baseUrl}token/add`, {
    method: 'POST',
    headers: handleHeaders(),
    body: JSON.stringify({
      Token: item, // токен пользователя
    }),
  }).then((res) => (res.ok ? res : Promise.reject(res.status)));
};

// смена пароля
export const handleChangePassword = (item: any, isEditPass: boolean) => {
  return fetch(`${baseUrl}EditPassword`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + Base64.encode(`${item.Mail}:${item.OldPassword}`),
    },
    body: JSON.stringify({
      // NewPassword: item.NewPassword, // Новый пароль
      NewPassword: Base64.encode(item.NewPassword), // Новый пароль
      // OldPassword: isEditPass ? Base64.encode(item.OldPassword) : item.OldPassword, // старый пароль
      // OldPassword: item.OldPassword, // старый пароль
      // Mail: item.Mail, //  эл. почта, она же и логин
    }),
  }).then((res) => handleReturn(res));
};

export const handleGetReport = () => {
  return fetch(`${baseUrl}Report`, {
    method: 'GET',
    headers: handleHeaders(),
  }).then((res) => (res.ok ? res : Promise.reject(res.status)));
};

export const handlePostFilesArr = (UID: string) => {
  return fetch(`${baseUrl}Files`, {
    method: 'POST',
    headers: handleHeaders(),
    body: JSON.stringify({ UID }),
  }).then((res) => handleReturn(res));
};

export const handlePostSomeFile = (fileUID: string, appUID: string) => {
  return fetch(`${baseUrl}File`, {
    method: 'POST',
    headers: handleHeaders(),
    body: JSON.stringify({ UID: fileUID, Owner: appUID }),
  }).then((res) => (res.ok ? res : Promise.reject(res.status)));
};

export const handleUploadSomeFile = async (file: File, appUID: string) => {
  const fileInfo = file.name.split('.');
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () =>
    fetch(`${baseUrl}UploadFile`, {
      method: 'POST',
      headers: handleHeaders(),
      // body: formData,
      body: JSON.stringify({
        UID: appUID,
        Type: fileInfo[1],
        Data: reader.result,
        NameFile: fileInfo[0],
      }),
    }).then((res) => (res.ok ? res : console.error(res.status)));
  reader.onerror = function (error) {
    console.error('Error: ', error);
  };
};
